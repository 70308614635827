// const sgMail = require("@sendgrid/mail")
import emailjs from "@emailjs/browser"
import { logEvent } from "firebase/analytics"
import { analytics } from "./App"
export default function sendThanksEmail(
  to_name: string = "anon",
  from_name: string = "anon",
  context_thought: string = "text hidden",
  collected_thought: string = "text hidden",
  reply_to: string,
  to_email: string
) {
  if (reply_to && to_email)
    emailjs.send(
      "default_service",
      "template_w1432nr",
      { to_name, from_name, collected_thought, context_thought, reply_to, to_email },
      "VFXAIIMaqEenjBrC9"
    )

  logEvent(analytics, "send-thanks-email", {
    timestamp: Date.now(),
    reply_to,
    to_email,
    from_name,
    to_name,
    collected_thought,
    context_thought,
  })

  //twilio
  //   sgMail.setApiKey(process.env.SENDGRID_API_KEY)
  //   const msg = {
  //     to: "davey@plexusnotes.com", // Change to your recipient
  //     from: "app@plexus.earth", // Change to your verified sender
  //     subject: "Sending with SendGrid is Fun",
  //     text: "and easy to do anywhere, even with Node.js",
  //     html: "<strong>and easy to do anywhere, even with Node.js</strong>",
  //   }
  //   sgMail
  //     .send(msg)
  //     .then(() => {
  //       console.log("Email sent")
  //     })
  //     .catch((error: any) => {
  //       console.error(error)
  //     })
}
