import React, { useContext, useEffect, useMemo, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Node } from "slate"
import { person } from "../../App"
import AuthContext from "../../AuthContext"
import { determineWhichEmbeddingToUse, getEmbeddingFromPost } from "../../embeddingLogic"
import { getReplyKeys } from "../../linkLogic"
import PostContext, { EdgeInfo, EdgeKind, TextPost } from "../../PostContext"
import { backendWriter, domainId, findRelatedPosts } from "../FullAppContainer"
import SearchFeed from "../Search/Feed/Feed"
import FeedInterface from "../Search/Feed/FeedInterface"
import FeedItem, { EdgeMap } from "../Search/Feed/FeedItem/FeedItem"
import LinkedThoughts from "../Search/Feed/FeedItem/LinkedThoughts"
import Reply from "../Search/Feed/FeedItem/Reply/Reply"
import { timestamp } from "../Search/Feed/FilteringLogic"
import "./Detail.css"
function topFunction() {
  const ele = document.getElementsByClassName("big-panel")[0]
  if (ele) {
    ele.scrollTop = 0 // For Safari
  }
}
const Detail = ({ providedPost, postId }: { providedPost: TextPost; postId: string }) => {
  const { posts } = useContext(PostContext)
  const { person } = useContext(AuthContext)
  const navigate = useNavigate()
  useEffect(() => {
    //programmatically set scroll to top
    topFunction()
  }, [])
  const [showRelevant, setShowRelevant] = useState(true)

  const authorsLinkKeys = (edges: EdgeMap) => {
    return Object.entries(edges)
      .filter(([key, val]: [string, EdgeInfo]) => {
        return val.authorId === backendWriter?.personId
      })
      .map((e) => e[0])
  }
  const linkKeys = Object.keys(providedPost.links ?? {})
  const antiLinkKeys = Object.keys(providedPost.antiLinks ?? {})
  const myLinkKeys = authorsLinkKeys(providedPost.links ?? {})
  const myAntiLinkKeys = authorsLinkKeys(providedPost.antiLinks ?? {})

  const replyKeys = getReplyKeys(providedPost.links)

  const detailRelatedThoughts = useMemo(() => {
    if (!providedPost || !providedPost.openaiEmbedding || !providedPost.slateValue) {
      return []
    } else {
      const vector: number[] =
        providedPost.slateValue.length <= 1
          ? getEmbeddingFromPost(providedPost)
          : providedPost.openaiEmbedding
      const related = showRelevant
        ? findRelatedPosts(posts, vector).filter((e) => e.id !== providedPost.id)
        : Object.values(posts).sort((b, a) => timestamp(a) - timestamp(b)) ?? []
      //all related that aren't linked:

      const suggested = related.filter((e) => e.id !== providedPost.id)

      const withoutMyLinks = suggested.filter(
        (thought) => ![...myLinkKeys, ...myAntiLinkKeys].includes(thought.id)
      )
      // const withoutReplyLinks = suggested.filter((thought) => !replyKeys.includes(thought.id))
      //putting links from others at the top of the feed
      const withLinksAtTop = withoutMyLinks.sort((a, b) => (linkKeys.includes(a.id) ? -1 : 1))

      return withLinksAtTop
    }
  }, [providedPost, posts, providedPost?.id, showRelevant])
  const numOtherLinks = providedPost.links
    ? Object.keys(providedPost.links).filter((e) => e !== postId).length
    : 0
  const location = useLocation()
  useEffect(() => {
    if (providedPost.text) document.title = providedPost.text + " (Plexus)"
  }, [providedPost])
  const [expanded, setExpanded] = useState(true)

  return (
    <div className="column">
      <div
        className="row"
        style={{
          alignItems: "center",
          margin: ".5em 0 0 .5em",
          fontSize: "1.6em",
        }}
      >
        <Link
          title="see your past thoughts / add a new one"
          style={{
            textAlign: "center",
            margin: "0em .5em",
            fontWeight: "bold",
            textDecoration: "none",
            fontSize: ".6em",
          }}
          to={"/p/" + (domainId ?? "")}
        >
          {/* ☖ */}
          {/* ☷🏠home */} home
        </Link>
        <Link
          title="go back to previously selected thought"
          style={{
            textAlign: "center",
            margin: "0em .5em",
            fontWeight: "bold",
            textDecoration: "none",
            fontSize: ".6em",

            // color: "gray",
          }}
          to={"/"} //fake, link just for styling
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)
          }}
        >
          back{/* {"←"} */}
        </Link>
        {/* <Link
          title="copy shareable link"
          style={{
            textAlign: "center",
            margin: "0em .5em",
            fontWeight: "bold",
            textDecoration: "none",
            fontSize: ".6em",
          }}
          to={"/"} //fake, link just for styling
          onClick={(e) => {
            e.preventDefault()
            navigator.clipboard.writeText(window.location.href)
            setTimeout(() => alert("Copied shareable link!\n"))
          }}
        >
          share
        </Link> */}
      </div>

      <div className="detail-view column">
        {providedPost && providedPost.slateValue ? (
          <div className="column">
            <FeedItem post={providedPost} originStory="principal" {...{ providedExpanded: true }} />
            {/* linked thoughts */}
            {/* <div
              style={{ background: "gray", height: "1px", width: "100%", marginBottom: ".1em" }}
            ></div> */}
            {!expanded ? (
              <></>
            ) : (
              // <></>
              <div className="detail-related-thoughts column">
                {/* <div
                  onClick={() => setShowRelevant((prev) => !prev)}
                  className="detail-related-thoughts-label "
                  title="(A note! Though the AI we use is awesome, it has biases / limitations. So please take all of our relatedness-suggestions with a sprinkle of salt.)"
                ></div> */}

                <FeedInterface
                  key={providedPost.id + "-related-thoughts"}
                  thoughts={detailRelatedThoughts}
                  relevantVsRecent={false}
                  showRelevant={showRelevant}
                  setShowRelevant={setShowRelevant}
                  inDetailView={true}
                  // meVsWe={true}
                />
              </div>
            )}
            {detailRelatedThoughts.length > 0 ? (
              <span
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  background: "transparent",
                  fontStyle: "italic",
                  color: "gray",
                  cursor: "pointer",
                  // border: "2px black solid",
                }}
                onClick={() => setExpanded((prev) => !prev)}
              >
                {expanded ? "hide" : "show"} suggested
              </span>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <span>{posts && posts[postId] ? "loading..." : "doesn't exist"}</span>
        )}
      </div>
    </div>
  )
}

export default Detail
