import { makeArr } from "./Components/Search/Feed/FeedItem/UzmaStack"
import { TextPost } from "./PostContext"
import users from "./community-users.json"
//@ts-ignore
import similarity from "compute-cosine-similarity"

export function determineWhichEmbeddingToUse(text: string, isFocus?: boolean): "doc" | "query" {
  //default method
  if (typeof isFocus !== "undefined") return isFocus ? "query" : "doc"

  if (text.includes("?") && text.length < 200) return "query"
  else if (text.length < 100) return "query"
  else return "doc"
}
export function getEmbeddingFromPost(post: TextPost, isFocus?: boolean): number[] {
  const { openaiEmbedding, openaiQueryEmbedding } = post
  if (!openaiEmbedding || !openaiQueryEmbedding) {
    return (openaiEmbedding ?? openaiQueryEmbedding) as number[]
  } else {
    return determineWhichEmbeddingToUse(post.text, isFocus) === "doc"
      ? openaiEmbedding
      : openaiQueryEmbedding
  }
}

export function averageTheseEmbeddings(embeddings: number[][]) {
  //assert
  if (!embeddings) return undefined
  if (embeddings) console.assert(embeddings[0].length == embeddings[embeddings.length - 1].length)
  const startingVector = embeddings[0]
  return embeddings
    .slice(1)
    .reduce((averageSoFar: number[], nextVector: number[], index: number) => {
      return averageSoFar.map(
        (val: number, itemIndex: number) =>
          ((index + 1) / (index + 2)) * val + (1 / (index + 2)) * nextVector[itemIndex]
      )
    }, startingVector)
}
const getAllUids = (postsArr: TextPost[]) => {
  const ids = postsArr.map((e) => e.authorId)
  const uniqueIds = new Set(ids)
  return uniqueIds
}
const getEigenvector = (postsArr: TextPost[], authorId: string) => {
  const myPosts = postsArr.filter((e) => e.authorId === authorId)
  const myEmbeddings: number[][] = myPosts
    .map((e: TextPost): number[] | undefined => e.openaiEmbedding)
    .filter((e: number[] | undefined): boolean => typeof e !== "undefined") as number[][]
  const myAverage = averageTheseEmbeddings(myEmbeddings)
  return myAverage
}

export const testGetAverageEmbeddings = (postsArr: TextPost[]) => {
  const myUid = "wSFLu3kTxYbJqvvqrRsnLpKDgIt1"
  const thePosts = postsArr.filter((e) => e.text.length > 40) // only do for longer strings

  const uids = Array.from(getAllUids(thePosts))
  const withVectors: [string, number[]][] = uids
    .map((uid: string) => {
      const vector = getEigenvector(thePosts, uid)
      return uid && vector ? [uid, vector] : undefined
    })
    .filter((e) => e) as [string, number[]][]

  const myPosts = thePosts.filter((e) => e.authorId === myUid)
  const othersPosts = thePosts.filter((e) => e.authorId !== myUid)
  const myEmbeddings: number[][] = myPosts
    .map((e: TextPost): number[] | undefined => e.openaiEmbedding)
    .filter((e: number[] | undefined): boolean => typeof e !== "undefined") as number[][]
  // const myAverage = averageTheseEmbeddings(myEmbeddings)

  const selectedPerson = withVectors[1]
  const myAverage = selectedPerson[1]

  const recommendations = withVectors.map((selectedPerson) => {
    const selectedVector = selectedPerson[1]
    const selectedName = users.users.filter((e) => e.localId === selectedPerson[0])[0]?.displayName
    //trying to determine interpersonal embeddings
    const similarAuthors = withVectors.sort((a, b) => {
      const similarities: { [key: string]: number } = {
        a: similarity(a[1], selectedVector),
        b: similarity(b[1], selectedVector),
      }
      return similarities.b - similarities.a
    })
    const similarAuthorNames = similarAuthors.map((e) => {
      const result = users.users.filter((user) => user.localId === e[0])
      return result && result[0] ? result[0].displayName : undefined
    })
    return [selectedName, similarAuthorNames, similarAuthors, selectedPerson]
  })

  //davey
  //lisa
  //aagat
  //noah
  //
  debugger

  const result = (
    othersPosts
      .map((e) =>
        e.openaiEmbedding ? [e.text, similarity(e.openaiEmbedding, myAverage), e] : undefined
      )
      .filter((e) => e) as [TextPost, number][]
  ).sort((a, b) => b[1] - a[1])
  console.log("BEST")

  result
    .slice(0, 20)
    .map((e) => e[0])
    .forEach((e) => console.log(e))
  console.log("NOW WORST")
  result
    .slice(-20)
    .map((e) => e[0])
    .forEach((e) => console.log(e))

  // debugger
}
