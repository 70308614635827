import React from "react"
import "./PostButton.css"

const PostButton = ({ appendPost }: { appendPost: () => void }) => {
  return (
    <div className="post-button simple-button" onClick={appendPost} title="enter">
      →
    </div>
  )
}

export default PostButton
