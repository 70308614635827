import { debug } from "console"
import { getDatabase } from "firebase/database"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { createEditor, Descendant, Editor, Node, Transforms } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import { backendWriter, domainId } from "../../Components/FullAppContainer"
import PostContext, { TextPost } from "../../PostContext"
import EditorContainer from "../EditorContainer"
import Search from "../Search/Search"
import { getSlateEditorString } from "../SlateEditorString"
import PostButton from "./PostButton"
import "../Search/Search.css"
import { NavigateOptions, useNavigate, useParams } from "react-router-dom"
import Detail from "../Detail/Detail"
import AuthContext from "../../AuthContext"
import { analytics, person } from "../../App"
import { logEvent } from "firebase/analytics"
import { isEmpty } from "@firebase/util"
const Create = ({ editor, resetEditor }: { editor: Editor; resetEditor: () => void }) => {
  const {
    setPosts,
    setPersonalFilter,
    relevanceFilter,
    setRelevanceFilter,
    posts,
    setSemanticallyRelatedPosts,
    loadingPosts,
  } = useContext(PostContext)
  const navigate = useNavigate()
  const appendPost = () => {
    //get person's name if not already
    const textOfNewPost = getSlateEditorString(editor)
    if (!textOfNewPost) return
    const { postWithId, addPostPromise } = backendWriter.addPost(editor.children, textOfNewPost)

    if (!postWithId) return
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    })

    logEvent(analytics, "append_post", { text: textOfNewPost, uid: person?.uid })

    navigate(`${domainId ? "/p/" + domainId : ""}/${postWithId.id}`)
    setSemanticallyRelatedPosts([])
    setEditorEmpty(true)
    resetEditor()
  }
  const [query, setQuery] = useState<string>("")
  const [editorEmpty, setEditorEmpty] = useState(true)

  useEffect(() => {
    setSearchPanelOpen(editorEmpty)
  }, [editorEmpty])
  //close at the beginning
  useEffect(() => {
    setTimeout(() => setSearchPanelOpen(false), 200)
  }, [])

  //duplicate in the feed item one
  const handleChange = (val: Descendant[]) => {
    const { selection } = editor
    if (selection) {
      const selectedText = Editor.string(editor, selection)
      setQuery(selectedText)

      if (selectedText.length == 0) {
        // setSearchPanelOpen(false)
        //deprecated
        // setRelevanceFilter(false)
      }
    }

    // check if editor is empty
    const editorText = Node.string(editor)
    setEditorEmpty(editorText.length == 0)

    //open again after clicking somewhere on the app screen
  }
  //set open when query changes
  //getting rid of this here
  // useEffect(() => {
  //   document.addEventListener("click", () => {
  //     setSearchPanelOpen(true)
  //   })
  // }, [])

  const [searchPanelOpen, setSearchPanelOpen] = useState(true)
  const { thoughtId } = useParams()
  //getting rid of this
  // useEffect(() => {
  //   if (!thoughtId) {
  //     setTimeout(() => setSearchPanelOpen(true), 100)
  //   }
  // }, [thoughtId])

  return (
    <div className="big-panel column">
      {thoughtId ? (
        posts[thoughtId] ? (
          <Detail providedPost={posts[thoughtId]} postId={thoughtId} key={thoughtId} />
        ) : (
          <div>loading thought...</div>
        )
      ) : (
        <>
          <div className={"create-panel column" + (searchPanelOpen ? " searchOpenToo" : "")}>
            <>
              <div className={"post-section column panel"}>
                <EditorContainer
                  {...{
                    editor,
                    handleEnter: appendPost,
                    handleChange,
                    query,
                    publishedAlready: false,
                  }}
                />
                {!editorEmpty ? (
                  <div className="row buttons-in-editor">
                    {/* <div className="simple-button">capture</div> */}
                    <PostButton {...{ appendPost }} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          </div>
          {searchPanelOpen ? (
            !loadingPosts ? (
              <Search
                query={query}
                setQuery={setQuery}
                {...{ open: searchPanelOpen, setOpen: setSearchPanelOpen, setSearchPanelOpen }}
              />
            ) : (
              <div
                style={{
                  textAlign: "center",
                  opacity: ".5",
                  color: "rgb(200,200,200)",
                }}
              >
                loading...
              </div>
            )
          ) : editorEmpty ? (
            <div
              style={{
                textAlign: "center",
                opacity: ".5",
                color: "rgb(130,130,130)",
                cursor: "pointer",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // height: "100%",
                // maxHeight: "10em",
              }}
              onClick={() => setSearchPanelOpen(true)}
            >
              see past thoughts
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  )
}

export default Create
