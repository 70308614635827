import { randomUUID } from "crypto"
import React, {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { BaseRange, createEditor, Descendant, Editor, Node, Point, Text, Transforms } from "slate"
import { withHistory } from "slate-history"
import { ReactEditor, Slate, useSlate, withReact } from "slate-react"
import { Editable } from "slate-react"
import { dict, docs } from "../database"
import "./EditorContainer.css"
//@ts-ignore
import uniqid from "uniqid"
import PostContext from "../PostContext"
import { cleanPhrase, cleanWord, tokenizeSentence } from "../FirebaseWriter"
import SearchFeed from "./Search/Feed/Feed"
import FeedItem from "./Search/Feed/FeedItem/FeedItem"
import { wordMatching } from "../App"
import { domainId } from "./FullAppContainer"
import { useParams } from "react-router-dom"

export const initialFakeValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]
export const initialValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]

// let todaysPrompt = getPlaceholder()
const EditorContainer = ({
  editor,
  handleEnter,
  handleChange,
  publishedAlready,
  givenValue,
  setGivenValue,
}: {
  editor: Editor
  handleEnter: Function
  handleChange: (value: Descendant[]) => void
  publishedAlready?: boolean
  givenValue?: Descendant[]
  setGivenValue?: Dispatch<any>
  idToExclude?: string
}) => {
  const [newValue, setNewValue] = useState(initialValue)
  const [value, setValue] = givenValue ? [givenValue, setGivenValue] : [newValue, setNewValue]
  const { todaysPrompt } = useContext(PostContext)
  const { placeId } = useParams()

  const decorate = ([node, path]: any) => {
    //decorate based on slate
    const nodeText = node.text

    if (!nodeText) return []

    const urls = findUrlsInText(nodeText)

    const ranges = urls.map(([url, index]: any[]) => {
      return {
        anchor: {
          path,
          offset: index,
        },
        focus: {
          path,
          offset: index + url.length,
        },
        decoration: "link",
      }
    })

    return ranges

    // const ranges: {
    //   anchor: Point
    //   focus: Point
    //   highlight?: boolean
    //   searchHighlight?: boolean
    //   id: string
    //   decoratedWordRange?: true
    //   range: BaseRange
    // }[] = []
    // then decorate with highlight
    // commented out for now, before there's enough text in the network.
    // if (wordMatching && Text.isText(node)) {
    //   const { text } = node
    //   if (!text) return ranges
    //   const decoratedWords = text.split(" ")
    //   let offset = 0
    //   decoratedWords.forEach((decoratedWord: string, i: number) => {
    //     //could do a better job at decorated just the word part
    //     const anchor = { path, offset }
    //     const focus = { path, offset: offset + decoratedWord.length }
    //     //if readOnly, means it's already been published
    //     const cleanedStem = cleanPhrase(decoratedWord)
    //     const wordInDictionary = cleanedStem in dictionary
    //     const appearsElsewhere =
    //       wordInDictionary && (!publishedAlready || Object.keys(dictionary[cleanedStem]).length > 1)

    //     ranges.push({
    //       anchor,
    //       focus,
    //       highlight: appearsElsewhere,
    //       decoratedWordRange: true,
    //       id: String(Math.random() * i) + decoratedWord,
    //       range: { focus, anchor },
    //       searchHighlight: false,
    //     })
    //     offset = offset + decoratedWord.length + 1
    //   })
    // }
    // //search highlighting
    // if (publishedAlready && query && Text.isText(node)) {
    //   const { text } = node
    //   const cleanedQuery = cleanPhrase(query)
    //   const parts = text.toLocaleLowerCase().split(cleanedQuery)
    //   let offset = 0

    //   parts.forEach((part, i) => {
    //     if (i !== 0) {
    //       const anchor = { path, offset: offset - cleanedQuery.length }
    //       const focus = { path, offset }
    //       ranges.push({
    //         anchor,
    //         focus,
    //         highlight: false,
    //         searchHighlight: true,
    //         range: { anchor, focus },
    //         decoratedWordRange: true,
    //         id: String(Math.random() * i) + cleanedQuery,
    //       })
    //     }
    //     offset = offset + part.length + cleanedQuery.length
    //   })
    // }

    //testing to see if this result works
  }
  const renderLeaf = (props: any) => {
    return <Leaf {...props} />
  }
  const handleKeyDown: KeyboardEventHandler = (e: any) => {
    if (e.key.toLocaleLowerCase() === "enter") {
      e.preventDefault()
      handleEnter()
    }
  }
  //Sets focus programmatically
  useEffect(() => {
    if (!publishedAlready) {
      ReactEditor.focus(editor)
      Transforms.select(editor, Editor.end(editor, []))
    }
  }, [])
  return (
    <div className="editor-container">
      <Slate editor={editor} value={value} onChange={handleChange}>
        <Editable
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
          placeholder={todaysPrompt ?? "What's on my mind?"}
          readOnly={publishedAlready && !wordMatching}
        />
      </Slate>
    </div>
  )
}

export const getPlaceholder = (placeId?: string): string | undefined => {
  return placeId && placeId in PlaceholdersPerDomain
    ? PlaceholdersPerDomain[placeId](new Date().getDate())
    : undefined
}

const PlaceholdersPerDomain: { [placeId: string]: (date: number) => string | undefined } = {
  friends: (day: number) => {
    switch (day) {
      // case 4:
      //   return "What's a good piece of advice you've received?"
      // case 5:
      //   return "What do you do when things aren't going your way?"
      // case 6:
      //   return "What's something you're struggling with?"
      // case 7:
      //   return "What's a quality in a friend that you admire?"
      // case 8:
      //   return "What's something you want to change? Big or small."
      // case 9:
      //   return "What's something you've learned from someone else here?"
      // case 10:
      //   return "What makes you feel crazy?"
      case 21:
      // case 22:
      //   return "What's on my mind?"
      //   break
      // case 23:
      //   return ""
      //   break
      // case 24:
      //   return ""
      //   break
      // case 25:
      //   return ""
      //   break
      // case 26:
      //   return ""
      //   break

      default:
        return "What's on your mind?"
    }
  },
}

const Leaf = ({ attributes, children, leaf }: any) => {
  const editor = useSlate()
  return (
    <span
      {...attributes}
      style={{
        color: leaf.searchHighlight ? "blue" : leaf.highlight ? "black" : "inherit",
        backgroundColor: leaf.searchHighlight && "lightblue",
        fontWeight: leaf.searchHighlight && "bold",
      }}
      onClick={(e) => {
        if (leaf.decoratedWordRange) {
          if (leaf.range) Transforms.select(editor, leaf.range)
        }
      }}
    >
      {children}
    </span>
  )
}

export default EditorContainer

export const isMobile = function () {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor)
  return check
}

export const findUrlsInText = (text: string) => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim

  const matches = text.match(urlRegex)

  return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())]) : []
}
