const domainDetails: {
  [domainId: string]: {
    name: string
    aPlaceTo?: string
    toGetStarted?: string
    weFeedOn?: true
    weFeedDefault?: true
    purpose?: string
  }
} = {
  growingtogether: {
    name: "Mind Health",
    aPlaceTo:
      "find community through your mental and emotional health lessons and reflections. We want to honor how far you’ve come!",
    toGetStarted: "share your thoughts and reflections to see what other people are saying.",
  },
  visioncommittee: {
    name: "Vision Committee",
    aPlaceTo: "make substantive connections with peers in the committee.",
    toGetStarted:
      "share a problem or resource, and you'll see the most related content from the rest of the Vision Committee.",
    // weFeedOn: false
  },
  phil401: {
    name: "Williams Philosophy 401",
    weFeedOn: true,
    weFeedDefault: true,
    purpose:
      "The shared brain for Shaddock's Freedom class is designed to help students connect to each other through shared thoughts",
  },
  SIA: {
    name: "Send It Around",
    weFeedOn: true,
    weFeedDefault: true,
    purpose: "Plan labor day",
  },
}
export default domainDetails
