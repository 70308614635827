import React, { useContext, useEffect } from "react"
import { Link, Navigate, useLocation, useNavigate, useResolvedPath } from "react-router-dom"
import "./LandingPage.css"

//auth 1
import { signInWithRedirect } from "firebase/auth"
import AuthContext from "../../AuthContext"
import { auth, provider } from "../../App"

const LandingPage = () => {
  const navigate = useNavigate()
  const url = useLocation()
  const { person, setPerson } = useContext(AuthContext)
  //If on the home page
  return person && url.pathname !== "/landing" ? (
    <Navigate to="/p" />
  ) : (
    <div className="landing-page">
      <div className="landing-page-text">
        Plexus is a shared brain for your community.
        <br></br>
        <br></br>
        Enter your community's brain, and you'll find a blank page. Share the problem on your mind,
        and you'll see, immediately, the most relevant thoughts from your peers.
        <br></br>
        <br></br>
        It's a place where meaningful connections — the kind that happen rarely in
        Reddit/Discord/Slack - happen routinely.
        <br></br>
        <br></br>
        {/* Existing forums and feeds become chaotic when more than 1% of participants post. Earth is
        designed for everyone to participate.
        <br></br>
        <br></br> */}
        {/* <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
          <iframe
            src="https://www.loom.com/embed/7e0c64a2faa2415b889596fb14c4e06c"
            allowFullScreen
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          ></iframe>
        </div> */}
        {/* <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
          <iframe
            src="https://www.loom.com/embed/53b5dd0a8e554ae89de616bb6b6a1a5a"
            frameBorder="0"
            // webkitallowFullScreen
            // mozallowfullscreen
            allowFullScreen
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          ></iframe>
        </div> */}
        {/* <a href="https://www.loom.com/share/7e0c64a2faa2415b889596fb14c4e06c">
          <p>Abortion Community - Demo</p>
          <img
            style={{ maxWidth: "240px" }}
            src="https://cdn.loom.com/sessions/thumbnails/7e0c64a2faa2415b889596fb14c4e06c-with-play.gif"
          />
        </a> */}
        <span>
          We can make you a shared brain. Just reach out:
          <a href="mailto:team@plexusnotes.com" style={{ display: "inline-flex" }}>
            team@plexusnotes.com
          </a>
          .
        </span>
        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Link to={"/demos"}>demo's</Link>

          <a href="https://plexus.substack.com/p/inverting-the-internet">purpose</a>
          <a href="https://innovative-phosphorus-3ab.notion.site/Joining-Plexus-370dd63ea16d463e8b34ec629625d344">
            careers
          </a>
          <a href="mailto:team@plexusnotes.com" style={{ display: "inline-flex" }}>
            contact
          </a>
          {/* <a href="https://igtqvlvn0xr.typeform.com/to/defoiwcd">waitlist</a> */}
          <a
            style={{ color: "white", cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              if (!person) signInWithRedirect(auth, provider)
              else navigate("/p")
            }}
          >
            log in
          </a>
        </div>
        {/* <Link to={} /> */}
        {/* <a href="https://plexusnotes.com">notes app</a> */}
      </div>
      <span></span>
    </div>
  )
}

export default LandingPage
