import { debug } from "console"
import moment from "moment"
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { NavigateOptions, useNavigate, useParams } from "react-router-dom"
import { createEditor, Descendant, Editor, Node } from "slate"
import { Slate, withReact, Editable } from "slate-react"
import AuthContext from "../../../../AuthContext"
import { backendWriter, domainId } from "../../../../Components/FullAppContainer"
import { EdgeInfo, EdgeKind, TextPost } from "../../../../PostContext"
import { usernamex } from "../../../../Components/FullAppContainer"
import EditorContainer from "../../../EditorContainer"
import "./FeedItem.css"
import "./PinButton.css"
import LinkedThoughts, { FeedItemType } from "./LinkedThoughts"
import SmartFeedItem from "./SmartFeedItem"
import UzmaStack, { makeArr } from "./UzmaStack"
import Reply from "./Reply/Reply"
import { unlink } from "fs"
import FeedInterface from "../FeedInterface"
import SimpleFeed from "./SimpleFeed"
import { analytics } from "../../../../App"
import { logEvent } from "firebase/analytics"
export type EdgeMap = { [postId: string]: EdgeInfo }

export const ExpansionEnabledEverywere = true

const FeedItem = ({
  post,
  originStory,
  lineage,
  breadcrumb,
  pinned,
  providedExpanded,
  setProvidedExpanded,
  isReply,
}: {
  post: TextPost
  originStory: FeedItemType
  lineage?: string[]
  breadcrumb?: boolean //whether to style like breadcrumb
  pinned?: true
  providedExpanded?: boolean
  setProvidedExpanded?: Dispatch<SetStateAction<boolean>>
  isReply?: boolean
}) => {
  //the updated author ID
  const { person } = useContext(AuthContext)
  const { thoughtId } = useParams()
  const navigate = useNavigate()

  const [editor] = useState(() => withReact(createEditor()))
  const value: Descendant[] = post.slateValue ?? []
  const handleChange = (val: Descendant[]) => {
    const { selection } = editor
    // if (selection && setQuery) {
    //   const selectedText = Editor.string(editor, selection)
    //   setQuery(selectedText)
    // }
    return val
  }
  const parentThoughtId = thoughtId ?? (lineage ? lineage[lineage.length - 1] : undefined)
  const [hover, setHover] = useState(false)
  const lineageLength = Object.keys(lineage ?? {}).length

  const [expanded, setExpanded] = useState<boolean>(providedExpanded ?? originStory === "principal") //|| (originStory === "suggested" && lineageLength <= 1)
  //in sync
  // useEffect(() => {
  //   if (typeof providedExpanded === "boolean") setExpanded(providedExpanded)
  // }, [providedExpanded])

  const otherLinks = post.links
    ? Object.entries(post.links).filter(
        ([key, value]) => key !== thoughtId && !lineage?.includes(key)
      )
    : []
  const numOtherLinks = otherLinks.length
  const otherReplies = post.links ? otherLinks.filter((e) => e[1].edgeKind === EdgeKind.REPLY) : []
  //is source the new one or the old?
  const repliesToThis = otherReplies.filter((e) => e[1].sourceId === post.id)
  const numOtherReplies = otherReplies.length
  const linkEntries = useMemo(() => Object.entries(post?.links ?? {}), [post])
  const linksAbove = linkEntries.filter((linkEntry) => {
    //if source is the reply, return false. source needs to be the post.
    return linkEntry[1].sourceId !== post.id
    // && linkEntry[1].edgeKind == EdgeKind.REPLY
  })
  const linksBelow = linkEntries.filter((linkEntry) => {
    return !linksAbove.map((e) => e[0]).includes(linkEntry[0])
  })
  const pins = linkEntries
    .filter((linkEntry) => linkEntry[1].edgeKind === EdgeKind.LINK)
    .filter((e) => !lineage?.includes(e[0]))
  const repliesAbove = linksAbove.filter((entry) => entry[1].edgeKind === EdgeKind.REPLY)
  const repliesBelow = linksBelow.filter((entry) => entry[1].edgeKind === EdgeKind.REPLY)

  const thoughtsBelow = [...repliesBelow, ...pins].sort((a, b) => {
    const timestamps: { [a: string]: number } = { a: a[1].timestamp ?? 0, b: b[1].timestamp ?? 0 }
    return -timestamps.b + timestamps.a
  })

  const unlinkable = lineage && lineage.length > 0 && lineage[0] in (post.links ?? {})
  const toggleExpandSync = () => {
    setExpanded((prev) => !prev)
    if (setProvidedExpanded) setProvidedExpanded((prev) => !prev)
  }
  // const addLinkClick = (e) => {}
  const emailable = post.authorId !== backendWriter.personId && post.authorEmail
  const emailFlow = (e: SyntheticEvent) => {
    if (emailable) {
      e.preventDefault()
      if (
        window.confirm(
          "Would you like to email " +
            (post.authorName ?? "the author") +
            " (" +
            post.authorEmail +
            ")?"
        )
      ) {
        logEvent(analytics, "opened-email-to", {
          to_name: post.authorName,
          to_email: post.authorEmail,
          appreciatedThoughtText: post.text,
          appreciatedThoughtId: post.id,
        })
        window.open(
          `mailto:${post.authorEmail}?subject=${"Your thought in Plexus"}&body=${
            'I appreciated your thought in Plexus: "' + post.text + '"'
          }`
        )
      }
    }
  }
  return value ? (
    <div className="feed-cont-cont-cont">
      {/* works pretty well if its only showing replies */}
      {repliesAbove && (!lineage || lineage.length == 0) ? (
        <>
          <LinkedThoughts
            {...{ links: repliesAbove, lineage: [...(lineage ?? []), post.id], above: true }}
          />
        </>
      ) : (
        <></>
      )}

      <div
        className={
          "feed-item-container-container " +
          originStory +
          (lineage && lineageLength > 0 ? "" : " top-level") +
          (expanded ? " expanded" : "")
        }
      >
        <div
          className={
            "feed-item-container " +
            originStory +
            (expanded ? " expanded" : "") +
            (typeof isReply === "boolean" ? (isReply ? " isReply" : " isPinned") : "")
          }
          onDoubleClick={(e) => {
            // navigate((domainId ? "/p/" + domainId : "") + "/" + post.id)
          }}
          onClick={(e) => {
            if (breadcrumb || thoughtsBelow.length == 0)
              navigate((domainId ? "/p/" + domainId : "") + "/" + post.id)
            else toggleExpandSync()
          }}
        >
          <div
            key={post.id}
            className={
              "feed-item" + " bubbley " + originStory + (lineageLength > 0 ? "" : " top-level")
            } //bubbley might depend on detail view itself.
          >
            {breadcrumb ? (
              <></>
            ) : (
              <div className="feed-item-header">
                <div className="row" style={{}}>
                  {true ? (
                    <span
                      onClick={(e) => {
                        emailFlow(e)
                      }}
                      className={"author-tag" + (emailable ? " emailable" : "")}
                    >
                      {" "}
                      {post.authorName ?? post.authorId} &nbsp;&nbsp;
                    </span>
                  ) : (
                    <></>
                  )}
                  <span>{post.timestamp ? moment(post.timestamp).fromNow() : ""}</span>
                </div>
                &nbsp;&nbsp;
                {thoughtId !== post.id ? (
                  <div
                    title="Zoom in on this thought."
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate((domainId ? "/p/" + domainId : "") + "/" + post.id)
                    }}
                    className={"feed-item-button"}
                  >
                    open
                    {/*  */}
                  </div>
                ) : (
                  <></>
                )}
                {/* &nbsp;&nbsp;
                {emailable ? (
                  <div onClick={emailFlow} className={"feed-item-button"}>
                    email
                  </div>
                ) : (
                  <></>
                )} */}
                &nbsp;&nbsp;
                <div
                  className={"feed-item-buttons row"}
                  onMouseOver={() => setHover(true)}
                  onMouseOut={() => setHover(false)}
                >
                  {post.authorID === person?.uid || post.authorName === usernamex ? (
                    hover ? ( //
                      <>
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            backendWriter.deletePost(post)
                            //if detail view
                            if (originStory === "principal") navigate("/p/" + (domainId ?? ""))
                          }}
                          className={"feed-item-button"}
                        >
                          delete
                        </div>
                        &nbsp; &nbsp;
                      </>
                    ) : (
                      //padding to make it bigger to click on, to take up the same room (ish) that "delete" would
                      <span
                        style={{
                          padding: "0 1em",
                          fontSize: "1.1em",
                          marginTop: "-.2em",
                          marginBottom: "-.0em",
                          fontWeight: "bold",
                        }}
                      >
                        ⋮
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <span
                  style={{
                    color: "gainsboro",
                    fontSize: ".4em",
                    fontStyle: "italic",
                    margin: "0 .2em",
                    wordBreak: "break-all",
                  }}
                >
                  {post.prompt
                    ? post.prompt.length > 36
                      ? "..." + post.prompt.slice(-24)
                      : post.prompt
                    : ""}
                </span>
              </div>
            )}

            <div className="row">
              {/* <span style={{ padding: " .25em" }}>●</span> */}
              <EditorContainer
                {...{
                  editor,
                  handleChange,
                  handleEnter: () => {},
                  givenValue: value,
                  publishedAlready: true,
                  idToExclude: post.id,
                }}
              />
            </div>
            {numOtherLinks > 0 && !expanded && !breadcrumb ? (
              <span
                style={{ opacity: "gray", fontSize: ".85em" }}
                onClick={(e) => {
                  // debugger
                  // if (!ExpansionEnabledEverywere) {
                  // } else {
                  // setExpanded(true)
                  // e.stopPropagation()
                  // }
                }}
              >
                {false && numOtherLinks > 0 ? (
                  <span>
                    <span style={{ fontSize: ".9em" }}>{String(numOtherLinks)}</span>{" "}
                    {numOtherLinks > 1 ? "connections" : "connection"}
                    &nbsp; &nbsp;
                  </span>
                ) : (
                  <></>
                )}
                {/* used to be separated by connection type: reply andpin */}
                {/* {repliesToThis.length > 0 ? (
                  <span>
                    <span style={{ fontSize: ".9em" }}>{String(repliesToThis.length)}</span>{" "}
                    {repliesToThis.length > 1 ? "replies" : "reply"}
                    &nbsp; &nbsp;
                  </span>
                ) : (
                  <></>
                )}
                {numOtherLinks - numOtherReplies > 0 ? (
                  <span>
                    <span style={{ fontSize: "smaller" }}>
                      {String(numOtherLinks - numOtherReplies)}
                    </span>{" "}
                    {numOtherLinks - numOtherReplies > 1 ? "pins" : "pin"}
                  </span>
                ) : (
                  <></>
                )} */}
              </span>
            ) : (
              <></>
            )}
            {/* commenting out for now, with detail view trial */}
            {/* only show linked thoughts in detail view, for now */}
          </div>
          {/* comment out for now */}
          <br></br>
          {!isReply && lineage && lineage?.length > 0 && lineage[0] && !breadcrumb ? (
            <div className="row">
              <div
                className={"pin-button" + (unlinkable ? " unlink" : "")}
                style={{ fontSize: "1em" }}
                title={(unlinkable ? "dis" : "") + "connect this thought and the title-thought"}
                onClick={(e) => {
                  e.stopPropagation()
                  //if linked to principal thought, then the option is to unlink
                  if (unlinkable) {
                    backendWriter.deleteLink(lineage[0], post.id)
                  } else {
                    //@ts-ignore
                    backendWriter.addLink(lineage[0], post.id, {
                      sourceId: lineage[0],
                      authorId: person?.uid ?? usernamex,
                      edgeKind: EdgeKind.LINK,
                    })
                  }
                }}
              >
                {"✕"}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {expanded && thoughtsBelow ? (
          <>
            {repliesBelow.length > 0 ? (
              <>
                <span className="section-label">Replies</span>
                <LinkedThoughts
                  links={repliesBelow}
                  lineage={[...(lineage ?? []), post.id]}
                  above={false}
                />
              </>
            ) : (
              <></>
            )}
            {pins.length > 0 ? (
              <>
                <span className="section-label">Pins</span>
                <LinkedThoughts
                  links={pins}
                  lineage={[...(lineage ?? []), post.id]}
                  above={false}
                />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {originStory === "principal" && expanded ? <Reply to={post.id} /> : <></>}
        {pins.length > 0 && expanded ? ( //originStory === "principal" &&
          <>
            {/* <div
            style={{
              width: "100%",
              textAlign: "center",
              fontStyle: "italic",
              fontSize: ".8em",
              marginTop: "-.8em",
            }}
          >
            pinned thoughts
          </div> */}
            {/* <SimpleFeed keys={pins.map((e) => e[0])} lineage={[...(lineage ?? []), post.id]} />{" "} */}
          </>
        ) : (
          <></>
        )}
        {["suggested", "linked", "principal"].includes(originStory) ? (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
            onClick={(e) => {
              if (!ExpansionEnabledEverywere && originStory !== "principal") {
                e.stopPropagation()
                navigate((domainId ? "/p/" + domainId : "") + "/" + post.id)
              } else {
                toggleExpandSync()
                e.stopPropagation()
              }
            }}
          >
            {repliesToThis.length + pins.length > 0 && !breadcrumb ? (
              expanded ? (
                // @ts-ignore
                (!lineage || (lineageLength < 1 && ExpansionEnabledEverywere)) &&
                originStory !== "principal" &&
                false ? (
                  <span
                    style={{
                      cursor: "pointer",
                      height: ".9em",
                      fontSize: "1.5em",
                      textAlign: "center",
                      // borderTop: "2px solid gainsboro",
                    }}
                  >
                    ^
                  </span>
                ) : (
                  <></>
                )
              ) : lineageLength <= 3 ? (
                <UzmaStack num={numOtherLinks} />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FeedItem
